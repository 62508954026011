import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toast";
import { Tabs, Card, Divider, Row, Col, Switch, Input, Collapse, Button, Form } from "antd";
import { BaseSelect } from 'components/Elements';
import PageTitle from "components/PageTitle";
import { getStores } from "requests/store";
import { setupCatalog, getCatalogConfig, saveCatalogConfig } from "requests/waCatalog";

const WaCatalog = () => {
    const location = useLocation();

    const titles = [{ path: location.pathname, title: 'WA Catalog' }];

    const [stores, setStores] = useState([]);
    const [selectedStoreId, setSelectedStoreId] = useState(null);
    const [catalogs, setCatalogs] = useState([]);
    const [config, setConfig] = useState(null);

    const onClickFacebookButton = () => {
        if (!selectedStoreId) {
            toast.error('Please choose one store!');
            return;
        }

        window?.FB.login(async function (response) {
            if (response.authResponse) {
                //Use this token to call the debug_token API and get the shared WABA's ID
                setupCatalog({
                    user_store_id: selectedStoreId,
                    signup_code: response.authResponse?.code
                });
                window.location.reload();
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: '395739639654045', // configuration ID obtained in the previous step goes here
            response_type: 'code',     // must be set to 'code' for System User access token
            override_default_response_type: true,
            extras: {
                setup: {
                    sessionInfoVersion: 2,  //  Receive Session Logging Info
                }
            }
        });
    }

    const onSubmit = async (data) => {
        try {
            const payload = {
                user_store_id: selectedStoreId,
                status: data.status,
                config: {
                    catalog_id: data.catalog_id
                }
            };

            await saveCatalogConfig(payload);
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    useEffect(() => {
        (async () => {
            const response = await getStores({ status: 1 });

            setStores(response.records);
        })()
    }, [])

    useEffect(() => {
        if (selectedStoreId) {
            (async () => {
                const response = await getCatalogConfig({ user_store_id: selectedStoreId });
    
                setConfig(response.record);
                setCatalogs(response.catalogs);
            })()
        }
    }, [selectedStoreId])

    return (
        <div>
            <PageTitle titles={titles} />
            <Card className="mt-16">
                <div>Please choose one store to setup catalog config. If you haven't connected to Facebook, please click the button <strong>Connect with Facebook</strong></div>
                <Row align="middle" className="mt-8">
                    <BaseSelect
                        className="w-50"
                        options={stores}
                        optionLabel="store_name"
                        optionValue="id"
                        defaultText="Select store"
                        onChange={setSelectedStoreId}
                    />
                    <button
                        style={{
                            backgroundColor: '#1877f2',
                            border: 0,
                            borderRadius: '4px',
                            color: '#fff',
                            cursor: 'pointer',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            height: '40px',
                            padding: '0 24px',
                            marginLeft: '16px'
                        }}
                        type="button"
                        onClick={onClickFacebookButton}
                    >
                        Continue with Facebook
                    </button>
                </Row>
            </Card>
            {selectedStoreId && (
                <Form
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <Card className="mt-16">
                        <Form.Item hidden name="access_token">
                            <Input value="" />
                        </Form.Item>
                        <Form.Item hidden name="refresh_token">
                            <Input value="" />
                        </Form.Item>
                        <Row className='mb-16' gutter={[32, 32]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item label="Enable sync products" name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item label="Catalog" name="catalog_id">
                                    <BaseSelect
                                        className="w-100"
                                        options={catalogs}
                                        optionLabel="name"
                                        optionValue="id"
                                        value={config?.config?.catalog_id || ''}
                                        defaultText="Select catalog"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Row className="mt-16" justify="end">
                        <Button htmlType="submit" type="primary">Save</Button>
                    </Row>
                </Form>
            )}
        </div>
    )
}

export default WaCatalog;