import api from 'utils/api';
import { toast } from 'react-toast';

/**
 * Whatsapp Catalogs API
 */

export const setupCatalog = (data) => {
    return new Promise((resolve, reject) => {
        return api.post('/merchant/wa-catalogs/setup', data).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export const getCatalogConfig = (query) => {
    return new Promise((resolve, reject) => {
        return api.get('/merchant/wa-catalogs', { params: query }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export const saveCatalogConfig = (data) => {
    return new Promise((resolve, reject) => {
        return api.post('/merchant/wa-catalogs', data).then((response) => {
            toast.success("Update config successfully");
            resolve(response.data);
        }).catch((err) => {
            toast.error(err.response.data.message);
            reject(err);
        });
    });
};
